import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../../images/intro.jpg";
import { Button } from "@material-ui/core";
import MusicPlatformSelectionModal from "../MusicPlatformSelectionModal";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "100px",
  },
  bigImage: {
    height: "60vh",
    width: "100vw",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
    backgroundImage: `url(${image})`,
    "@media(max-width:700px)": {
      height: "40vh",
    },
  },
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.1)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    ":hover&": {
      background: "rgba(0, 0, 0, 0.4)",
    },
  },
  listenNowContainer: {
    right: "5%",
    top: "10%",
    position: "absolute",
    marginLeft: "70%",
    color: "white",
    "@media(max-width:700px)": {
      marginLeft: "0%",
    },
  },
  buttonListenNow: {
    borderColor: "white",
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    borderWidth: "4px",
    borderRadius: "0px",
    "@media(max-width:700px)": {
      fontSize: "12px",
      borderWidth: "3px",
    },
  },
}));

export default function ImageIntro() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);

  const resetDialogState = () => {
    setOpenDialog(false);
  };
  const MusicDialog = openDialog && (
    <MusicPlatformSelectionModal
      reset={resetDialogState}
      openDialog={openDialog}
    />
  );
  return (
    <div className={classes.bigImage}>
      {MusicDialog}
      <div className={classes.overlay}>
        {/* <p>A full width background image built with HTML CSS.</p> */}
        <div className={classes.listenNowContainer}>
          <Button
            onClick={() => setOpenDialog(true)}
            variant="outlined"
            classes={{ root: classes.buttonListenNow }}
          >
            LISTEN NOW
          </Button>
        </div>
      </div>
    </div>
  );
}
