import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "36px",
    fontWeight: "bold",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "26px",
    },
  },
}));
export default function BlogsTitle() {
    const classes = useStyles();
  return (
    <div>
      <p className={classes.title}>Blogs</p>
    </div>
  );
}
