import { Grid } from "@material-ui/core";
import React from "react";
import YouthIntro from "./components/YouthIntro";
import { makeStyles } from "@material-ui/core";
import ImageIntro from "./components/ImageIntro";

const useStyles = makeStyles((theme) => ({}));
export default function Youth() {
  const classes = useStyles();
  return (
    <div id="youth">
      <Grid style={{maxWidth:"100%", marginBottom:"40px"}} container direction="column">
        <Grid item style={{width:"100vw"}}>
          <YouthIntro />
        </Grid>
        <Grid item></Grid>
      </Grid>
    </div>
  );
}
