import { Grid } from "@material-ui/core";
import React from "react";
import ContactForm from "./components/ContactForm";
import { makeStyles } from "@material-ui/core/styles";
import ContactIntro from "./components/ContactIntro";

const useStyles = makeStyles((theme) => ({
  container:{
    marginTop:"70px",
    ["@media(max-width:800px)"]: {
      // eslint-disable-line no-useless-computed-key
      marginTop:"55px",
    },
  },
  title: {
    fontSize:"32px",
    marginTop:"20px",
    ["@media(max-width:800px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize:"20px",
    },
  },
}));
export default function Contact() {
  const classes = useStyles();
  return (
    <div>
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <ContactIntro/>
        </Grid>
        <Grid item>
          <div className={classes.title}>Get In Touch</div>
        </Grid>
        <Grid item>
          <ContactForm />
        </Grid>
      </Grid>
    </div>
  );
}
