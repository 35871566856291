import React from "react";
import ButtonImage from "../../../Buttons/ButtonImage";

export default function HomeButtonDisplay() {
  const images = [
    {
      url: "https://ds-web-media.s3.amazonaws.com/with_children_1.jpg",
      title: "Children",
      width: "33.333%",
      target:"/ministries",
    },
    {
      url: "https://ds-web-media.s3.amazonaws.com/with_youth_2.jpg",
      title: "Youth",
      width: "33.3333%",
      target:"/ministries",
    },
    {
      url: "https://ds-web-media.s3.amazonaws.com/about_me.JPG",
      target: "/about",
      title:"About",
      width: "33.333%",
    },
  ];

  return (
    <div>
      <ButtonImage items={images} />
    </div>
  );
}
