import React, { Component } from "react";
import Carousel from "react-elastic-carousel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { IconButton } from "@material-ui/core";
import YoutubeCard from "./YoutubeCard";
import CarouselMenuTabs from "../CarouselMenuTabs";

export default class CarouselYoutube extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentItem: 0,
      value: 0,
      items: [
        {
          id: 1,
          title:
            "Don't leave me alone",
          src: "https://www.youtube.com/watch?v=LZR_MmH8v0M",
          type: "video",
          description:
            "Don't Leave Me Alone - Coptic Official Lyric Video",
        },
        {
          id: 2,
          title: "Dear precious Jesus",
          src: "https://www.youtube.com/watch?v=IRNWzbaSyXY&t=21s",
          type: "video",
          description: "Dear Precious Jesus - English Version",
        },
        {
          id: 3,
          title: "I have never seen",
          src: "https://www.youtube.com/watch?v=d-Z32dA5XIc&t=87s",
          type: "video",
          description: "I have never seen a God like you, my Lord",
        },
        {
          id: 4,
          title: "The God who stays",
          src: "https://www.youtube.com/watch?v=iCobz-2UXJY",
          type: "video",
          description: "Cover song with wonderful youth contribution.",
        },
        {
          id: 5,
          title: "Dont let go",
          src: "https://www.youtube.com/watch?v=YH8mXIjxp90",
          type: "video",
          description: "on't Let Go - Original Song",
        },
        {
          id: 6,
          title: "Our God",
          src: "https://www.youtube.com/watch?v=Jr-_iWIYD0Y",
          type: "video",
          description: "Our God - Chris Tomlin - Cover by Dahlia",
        },
        {
          id: 7,
          title: "Prince of Peace",
          src: "https://www.youtube.com/watch?v=LOg1t0d77i0",
          type: "video",
          description: "Prince of Peace is Risen, He is risen indeed ",
        },
        {
          id: 8,
          title: "Who am I",
          src: "https://www.youtube.com/watch?v=KMazXURI55E",
          type: "video",
          description: "Who am I, that the Lord of all the earth",
        }
        // {
        //   id: 9,
        //   title: "item #9",
        //   src: "https://www.youtube.com/watch?v=LOg1t0d77i0",
        //   type: "video",
        //   description: "Some text describing wtv is being displayed.",
        // },
      ],
    };
    this.goto = this.goto.bind(this);
    this.currentItemChange = this.currentItemChange.bind(this);
  }
  onNextClick = (current) => {
    if (current.index < 3) {
      this.goto(3);
    } else if (
      current.index < 6 &&
      (current.index === 3 || current.index > 3)
    ) {
      this.goto(6);
    }
    //this.setState({ currentItem: current.index });
    console.log("current Item: ", current.index);
  };
  goto(target) {
    this.carousel.goTo(target);
  };
  currentItemChange(current){
    this.setState({currentItem: current.index})
  }
  render() {
    const breakPoints = [
      { width: 1, itemsToShow: 1.2 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 768, itemsToShow: 2.5 },
      { width: 1200, itemsToShow: 3 },
    ];
    const myArrow = ({ type, onClick, isEdge }) => {
      const pointer =
        type === "PREV" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;
      return (
        <div></div>
        // <IconButton onClick={onClick} disabled={isEdge}>
        //   {pointer}
        // </IconButton>
      );
    };
    const { items, value, currentItem } = this.state;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <CarouselMenuTabs currentItem={currentItem} goTo={this.goto} />
        <Carousel
          ref={(ref) => (this.carousel = ref)}
          onNextStart={(current) => this.onNextClick(current)}
          //onPrevEnd={this.onCurrentChange}
          renderArrow={myArrow}
          breakPoints={breakPoints}
          onNextEnd={this.currentItemChange}
          onPrevEnd={this.currentItemChange}
        >
          {items.map((item) => (
            <div key={item.id} style={{ margin: "20px" }}>
              <YoutubeCard
                description={item.description}
                type={item.type}
                title={item.title}
                src={item.src}
              />
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
}
