import React from "react";
import { Parallax } from "react-parallax";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: "22px",
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
  },
  insideStyles: {
    background: "rgba(255, 255, 255, 0.8)",
    padding: 15,
    position: "absolute",
    width: "40%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "50%",
    },
  },
  container: {
    marginBottom: "100px",
    maxWidth:"1200px",
    margin:"auto",
    padding: "5vh",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "10px",
      marginBottom:"50px",
    },
  },
}));
const insideStyles = {
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  padding: 15,
  position: "absolute",
  width: "200px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

export default function AboutSecondaryContent() {
  const classes = useStyles();
    const image = "https://ds-web-media.s3.amazonaws.com/seasonal_march.jpeg"
  return (
    <div className={classes.container}>
      <Parallax bgImage={image} blur={{ min: -1, max: 3 }}>
        <div style={{ height: "400px" }}>
          <div className={classes.insideStyles}>
            <p className={classes.description}>
              Though I am the least deserving of all, He graciously gave me the
              privielege of praising and worship to share with the world the
              endless treasures available to them in Christ.
            </p>
          </div>
        </div>
      </Parallax>
    </div>
  );
}
