import { Button, Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  blogContainer: {
    letterSpacing: "0.35px",
    height: "55vh",
    maxWidth: "550px",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "16px",
    textSizeAdjust: "100%",
    marginBottom: "30px",
    width: "100%",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "95%",
      height: "auto",
      maxHeight: "600px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60vh",
    },
  },
  imageContainer: {
    maxHeight: "300px",
    overflow: "hidden",
    marginBottom: "30px",
    width: "100%",
    [theme.breakpoints.between("lg", "xl")]: {
      height: "300px",
    },
    [theme.breakpoints.between("xs", "md")]: {
      maxHeight: "250px",
    },
  },
  blogPreviewImage: {
    width: "100%",
    height: "auto",
  },
  blogPreviewImageCentered: {
    width: "100%",
    height: "auto",
    transform: "translateY(-30%)",
  },
  blogDescriptionContainer: {
    display: "flex",
    flexGrow: "1",
  },
  button: {
    padding: "12px",
    color: "#343534",
    borderColor: "#343534",
    fontSize: "14px",
    borderWidth: "3px",
    borderRadius: "0px",
    width: "50%",
    ":hover&": {
      color: "white",
      backgroundColor: "#343534",
    },
  },
}));
export default function BlogDisplay(props) {
  const { src, description, title, centered, id, displayTrigger } = props;
  const classes = useStyles();
  const history = useHistory();

  const triggerDisplay = () => {
    displayTrigger(id);
  };

  return (
    <Grid
      container
      className={classes.blogContainer}
      direction="column"
      justify="center"
      wrap="nowrap"
    >
      <Grid item className={classes.imageContainer}>
        <a
          onClick={() => triggerDisplay()}
          style={{ cursor: "pointer", display: "block", marginBottom: "30px" }}
        >
          <img
            className={
              centered
                ? classes.blogPreviewImageCentered
                : classes.blogPreviewImage
            }
            style={{ borderColor: "rgb(52, 53, 52)" }}
            src={src}
          />
        </a>
      </Grid>
      <Grid item className={classes.blogDescriptionContainer}>
        <Grid container direction="column">
          <Grid
            item
            style={{
              fontSize: "18px",
              fontWeight: "lighter",
              marginBottom: "10px",
            }}
          >
            <h2>{title}</h2>
          </Grid>
          <Grid item style={{ padding: "12px" }}>
            {description}
          </Grid>
          <div style={{ flexDirection: "column", flexGrow: "1" }} />
          <Grid item>
            <Button
              onClick={() => triggerDisplay()}
              variant="outlined"
              classes={{ root: classes.button }}
            >
              READ MORE
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
