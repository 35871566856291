import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "50px",
    overflowAnchor: "visible",
    overflow: "visible",
    zIndex:"1",
  },
  title: {
    position: "-webkit-sticky",
    overflow: "visible",
    paddingBottom: "10px",
    overflowAnchor: "visible",
    left: "0",
    right: "0",
    zIndex:"1",
    bottom: "0",
    position: "sticky",
    alignSelf: "flex-start",
    fontSize: "34px",
    backgroundColor: "white",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "24px",
    },
  },
}));
export default function HomeIntro() {
  const classes = useStyles();

  return (
    <div className={classes.title}>
      <p>Worship.Praise.Pray</p>
    </div>
  );
}
