import React, {useEffect} from "react";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tab: {
    fontWeight: "700",
    fontSize: "16px",
  },
  indicator:{
    backgroundColor:"rgb(33, 42, 47)",
    color:"rgb(33, 42, 47)"
  }
}));
export default function CarouselMenuTabs(props) {
  const { currentItem } = props;
  const [value, setValue] = React.useState(currentItem/3);
  const classes = useStyles();
  const tabs = [{ label: "Coptic" }, { label: "Faith" }, { label: "Easter" }];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      props.goTo(newValue);
    } else {
      props.goTo(newValue * 3);
    }
  };
  // if (currentItem < 3) {
  //   setValue(0);
  // } else if (currentItem === 3 || currentItem < 6) {
  //   setValue(1);
  // } else if (currentItem === 6 || currentItem < 9) {
  //   setValue(2);
  // }
  useEffect(() => {
    setValue(Math.floor(currentItem/3))
  }, [currentItem])
  return (
    <div style={{marginBottom:"20px", borderBottom:"3px solid rgb(211, 212, 213)", width:"90%", margin:"auto"}}>
      <Tabs
        classes={{indicator:classes.indicator}}
        value={value}
        style={{ borderBottom: "20px" }}
        onChange={handleChange}
        aria-label="disabled tabs example"
        centered
      >
        {tabs.map((item, index) => (
          <Tab
            key={item.label + index.toString()}
            className={classes.tab}
            label={item.label}
          />
        ))}
      </Tabs>
    </div>
  );
}
