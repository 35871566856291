import { Grid } from "@material-ui/core";
import React from "react";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "380px",
    [theme.breakpoints.down("sm")]: {
      width: 310,
    },
    [theme.breakpoints.up("lg")]: {
      width: "420px",
    },
  },
  largerContainer: {
    margin: "auto",
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: 330,
    },
    [theme.breakpoints.up("lg")]: {
      width: "440px",
    },
  },
  description: {
    textAlign: "left",
    paddingTop: "12px",
  },
  video: {
    height: "280px",

    [theme.breakpoints.down("sm")]: {
      height: 230,
    },
    width: "inherit",
    position: "relative",
  },
  largerVideo: {
    height: "320px",

    [theme.breakpoints.down("sm")]: {
      height: 230,
    },
    width: "inherit",
    position: "relative",
  },
}));
export default function YoutubeDisplay(props) {
  const { title, description, src, larger } = props;
  const classes = useStyles();

  return (
    <div className={larger ? classes.largerContainer : classes.container}>
      <Grid container direction="column">
        <Grid item className={larger? classes.largerVideo:classes.video}>
          <ReactPlayer
            className="player"
            width="100%"
            height="100%"
            url={src}
            controls={true}
          />
        </Grid>
        <Grid item>
          <div className={classes.description}>{description}</div>
        </Grid>
      </Grid>
    </div>
  );
}
