import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTrail, animated } from "react-spring";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "rgb(33, 42, 47)",
    marginBottom: "20px",
    marginTop: "20px",
    fontSize: "32px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "25px",
    },
  },
}));

export default function Title() {
  const classes = useStyles();
  const items = ["Enternal", "Treasure", "Music"];
  const [toggle, set] = React.useState(true);
  const config = { mass: 2, tension: 2000, friction: 400 };

  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 40 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <Grid container direciton="column" justify="center">
      <h1 style={{ minHeight: items.length * 40 }}>
        {trail.map(({ x, height, ...rest }, index) => (
          <animated.span
            key={items[index]}
            className="trails-text"
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
            }}
          >
            <animated.span style={{ height }}>{items[index]}</animated.span>
          </animated.span>
        ))}
      </h1>
    </Grid>
  );
}
