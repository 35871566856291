import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop:"16px",
  },
  footer_link: {
    color: "white",
  },
}));
export default function QuickLinks() {
  const classes = useStyles();
  const links = [
    {
      name: "Get In Touch",
      target: "/contact",
    },
    {
      name: "About",
      target: "/about",
    },
    {
      name: "Blogs",
      target: "/blogs",
    },
  ];
  return (
    <Grid container direction="column" className={classes.container}>
      <ul
        style={{
          listStyleType:"none",
          margin: "auto",
          paddingTop: "10px",
          paddingLeft: "12px",
          paddingRight: "0px",
          paddingBottom: "12px",
        }}
      >
        {links.map((item, index) => (
          <li key={"link-" + index} className="footer_link_list_item">
            <a href={item.target} className="footer_link">
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </Grid>
  );
}
