import React from "react";
import Carousel from "react-multi-carousel";
import YoutubeDisplay from "../../YoutubeDisplay/YoutubeDisplay";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@material-ui/core/styles";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 1,
    paritialVisibilityGutter: 0,
  },
  md: {
    breakpoint: { max: 1500, min: 1000 },
    items: 1,
    paritialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1000, min: 700 },
    items: 1,
    paritialVisibilityGutter: 0,
  },
  sm:{
    breakpoint: { max: 700, min: 600 },
    items: 1,
    paritialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0,
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
      margin:"auto",
      width:"100%",
      height:"100%",
      [theme.breakpoints.down('md')]:{
          width:"90%"
      }
  },
  title:{
      fontSize:"36px",
      fontWeight: 500,
      paddingBottom:"30px",
      [theme.breakpoints.down('md')]:{
          fontSize:"28px",
      }
  }
}));
export default function CarouselVideo(props) {
  const { videos } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Carousel style={{marginTop:"auto", marginBottom:"auto"}} partialVisbile responsive={responsive}>
        {videos.map((item, index) => {
          return (
            <div style={{margin:"auto"}} key={index}>
                <div className={classes.title}>{item.title}</div>
              <YoutubeDisplay larger={true} src={item.src} description={item.description} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
