const { default: requests } = require("../helpers/http")

const mailEndpoint = "https://57afagzhb8.execute-api.us-east-1.amazonaws.com/mail";

const mailApi = {
    sendMail:(data) => {
        let params = {
            accountSender: "dahlbasht@gmail.com",
            destination: "dahlbasht@gmail.com",
            emailParams: data
        }
        return requests.post(mailEndpoint+"?type=contact_inquiry", params)
    }
}

export default mailApi;