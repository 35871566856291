import { Grid } from "@material-ui/core";
import React from "react";
import Children from "./components/Children";
import { makeStyles } from "@material-ui/core/styles";
import Youth from "./components/Youth";

const useStyles = makeStyles((theme) => ({
  children: { width: "100%", margin: "auto",},
  youth: {},
}));
export default function Services() {
  const classes = useStyles();

  return (
    <div>
      <Grid container direction="column">
        <Grid item className={classes.children}>
          <Children />
        </Grid>
        <Grid item className={classes.youth}>
          <Youth/>
        </Grid>
      </Grid>
    </div>
  );
}
