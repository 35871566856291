import { Button, Grid } from "@material-ui/core";
import React from "react";
import image from "../images/blog_1.jpg";
import image2 from "../images/about.jpg";
import BlogDisplay from "./components/BlogDisplay";
import { makeStyles } from "@material-ui/core/styles";
import BlogsTitle from "./components/BlogsTitle";
import BlogsLoader from "./components/BlogsLoader";
import Blog from "./components/Blog/";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../../helpers/ScrollToTop";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "50px",
  },
  blogsItems: {
    padding: "40px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.between("sm", "lg")]: {
      padding: "20px",
    },
    [theme.breakpoints.between("sm", "xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: "95%",
      padding: "12px",
    },
  },
  button: {
    padding: "12px",
    color: "#343534",
    fontSize: "18px",
    borderWidth: "0px",
    borderRadius: "0px",
    border:"none",
    width: "50%",
    ":hover&": {
      shadow:"none",
    },
  },
}));
const tempBlogs = [
  {
    title: "Worship is healing and uplifting!",
    config: {
      fontSize: "larger",
    },
    external_url:{image: image},
    author:"Fr.Botrous Philopos",
    description:"Author: Fr.Botrous Philopos",
    paragraphs: [
      "Worshiping the Lord brings us in  the presence of the Lord Himself. One’s whole being is lifted up above troubles of the earth and we experience true joy, and true peace.",
      "Spiritual hymns are heals the whole human being. 1 Samuel 16 tells us that when David played his psalm on the harp, King Saul was refreshed and healed from his ailments.",
      "“Come, let us bow down in worship, let us kneel before the Lord our Maker” (Psalm 95:6).",
    ]
  },
  //{
  //   external_url: { image: image },
  //   id: "id1",
  //   centered: false,
  //   title: "The Perfect Vegan Festive Feast",
  //   description: "Top tips to make a delicious vegan festive feast.",
  //   paragraphs: [
  //     "Top tips to make a delicious vegan festive feast.",
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
  //   ],
  // },
  // {
  //   external_url: { image: image2 },
  //   id: "id2",
  //   centered: true,
  //   title: "The Perfect Vegan Festive Feast",
  //   description: "Top tips to make a delicious vegan festive feast.",
  //   paragraphs: [
  //     "Top tips to make a delicious vegan festive feast.",
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
  //   ],
  // },
  // {
  //   external_url: {
  //     image: "https://ds-web-media.s3.amazonaws.com/with_children_1.jpg",
  //   },
  //   id: "id3",
  //   centered: true,
  //   title: "The Perfect Vegan Festive Feast",
  //   description: "Top tips to make a delicious vegan festive feast.",
  //   paragraphs: [
  //     "Top tips to make a delicious vegan festive feast.",
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
  //   ],
  // },
];
export default function Blogs() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [blogToDisplay, setBlogToDisplay] = React.useState("");
  const [locationId, setLocationId] = React.useState("");
  const location = useLocation();

  const displayTrigger = (id) => {
    setBlogToDisplay(tempBlogs.find((x) => x.id === id));
    setLocationId(location.key);
  };

  const blog = blogToDisplay && (
    <Grid container direction="column">
      <Grid item>
        <Blog
          centered={blogToDisplay.centered}
          title={blogToDisplay.title}
          src={blogToDisplay.external_url.image}
          paragraphs={blogToDisplay.paragraphs}
          author={blogToDisplay.author}
        />
      </Grid>
      <Grid item>
        <Button
          onClick={()=> setBlogToDisplay('')}
          variant="outlined"
          className={classes.button}
          startIcon={<ArrowBackIcon/>}
        >
          Blogs
        </Button>
      </Grid>
    </Grid>
  );
  if (blogToDisplay && location.key !== locationId) {
    setBlogToDisplay("");
  }
  return (
    <ScrollToTop>
      <div className={classes.container}>
        {blogToDisplay ? (
          blog
        ) : (
          <Grid container direction="column">
            <Grid item>
              <BlogsTitle />
            </Grid>
            {loading ? (
              <Grid item>
                <BlogsLoader />
              </Grid>
            ) : null}
            <Grid item>
              <Grid container direction="row" justify="center">
                {tempBlogs.map((item, index) => (
                  <Grid item key={index} className={classes.blogsItems}>
                    <BlogDisplay
                      author={item.author}
                      config={item.config}
                      centered={item.centered}
                      displayTrigger={displayTrigger}
                      id={item.id}
                      src={item.external_url.image}
                      title={item.title}
                      description={item.description}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </ScrollToTop>
  );
}
