import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import image from "../../../images/music_note_1.gif";
import MusicPlatformSelectionModal from "../MusicPlatformSelectionModal";
import Title from "../../../Typography/title";

const useStyles = makeStyles((theme) => ({
  leftContainer: {
    backgroundColor: "#EEEBE5",
    color: "#212a2f",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "16px",
    },
  },
  leftContent: {
    height: "100%",
  },
  rightContainer: {
    backgroundColor: "#F9F9F7",
    color: "#212a2f",
    width: "50%",
    height: "100%",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      padding:0,
    },
  },
  title: {
    fontSize: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  description: {
    fontSize: "20px",
    maxWidth: "500px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  image: {
    height: "100%",
    margin: "auto",
    ":hover&": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
  },
  imageContainer: {
    margin: "auto",
    height: "100%",
  },
  button: {
    padding: "8px",
    color: "#343534",
    borderColor: "#343534",
    fontSize: "14px",
    fontWeight: "700",
    borderWidth: "3px",
    borderRadius: "0px",
    width: "100%",
    ":hover&": {
      color: "white",
      backgroundColor: "#343534",
    },
  },
  container: {
    height: "30vh",
    minHeight:"300px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
}));
export default function HomeIntroV2() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const resetDialogState = () => {
    setOpenDialog(false);
  };

  const MusicDialog = openDialog && (
    <MusicPlatformSelectionModal
      reset={resetDialogState}
      openDialog={openDialog}
    />
  );
  return (
    <div className={classes.container}>
      {MusicDialog}
      <Grid
        style={{ height: "100%" }}
        container
        direction="row"
        justify="center"
      >
        <Grid item className={classes.leftContainer}>
          <Grid container direction="column">
            <Grid item style={{ margin: "auto" }}>
              <Title text={["Endless","Treasures","Music"]}/>
              {/* <h2 className={classes.title}>Worship.PraisePray</h2> */}
              <p className={classes.description}>
                Praise and worship is about making a difference in people's lives
                using our talents. Starting with people that contribute to the
                ministry until those who listen to it.
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.rightContainer}>
          <div className={classes.imageContainer}>
            <img
              onClick={() => setOpenDialog(true)}
              className={classes.image}
              src={image}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
