import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SpotifyImage from "../../../images/Spotify_Icon_RGB_Black.png";
import AppleMusicIcon from "../../../images/Apple_Music_Icon_blk_lg_072420.svg";
import SoundCloudIcon from "../../../images/soundcloud-128.png";
import YouTubeIcon from "@material-ui/icons/YouTube";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  icons: {
    color: "white",
    fontSize: "40px",
    "@media(max-width:800px)": {
      fontSize: "30px",
    },
  },
  iconsImage: {
    width: "40px",
    "@media(max-width:800px)": {
      width: "30px",
    },
  },
}));

export default function MusicPlatformSelectionModal(props) {
  const classes = useStyles();
  const { openDialog, reset } = props;
  const [open, setOpen] = React.useState(openDialog);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Select your favourite platform!"}
        </DialogTitle>
        <DialogContent style={{ marginLeft: "auto", marginRight: "auto" }}>
          <Grid container style={{ marginLeft: "auto", marginRight: "auto" }}>
            <Grid item>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://open.spotify.com/artist/6Zbdtl0M81DYdfxim2PxU7"
                  )
                }
              >
                <img className={classes.iconsImage} src={SpotifyImage} />
              </IconButton>
            </Grid>{" "}
            <Grid item>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://music.apple.com/us/artist/dahlia-bashta/1481779817"
                  )
                }
              >
                <img className={classes.iconsImage} src={AppleMusicIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://soundcloud.com/user-427147094"
                  )
                }
              >
                <img className={classes.iconsImage} src={SoundCloudIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCL6o2n0beE80XnA5fgRVz3w"
                  )
                }
              >
                <YouTubeIcon style={{ color: "black" }} fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
