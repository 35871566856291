import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import BaseButton from "../../../Buttons/BaseButton";

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: "#e0d3c8",
    backgroundColor: "rgb(237, 235, 231)",
    padding: "5vh",
    [theme.breakpoints.between("lg", "xl")]: {},
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "1vh",
    },
    marginBottom: "40px",
  },
  seasonImage: {
    width: "100%",
    height: "inherit",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  titleContainer: {
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
  },
  imageContainer: {
    margin: "auto",
    maxHeight: "600px",
    width: "50%",
    paddingLeft: "20px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "none",
    },
  },
  title: {
    fontWeight: "bolder",
    fontSize: "36px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "25px",
    },
  },
  descriptionContainer: {
    width: "80%",
    paddingLeft: "5%",
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      padding: "10px",
    },
  },
  description: {
    fontSize: "24px",
    textSizeAdjust: "100%",
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "18px",
    },
  },
  contentContainer: {
    margin: "auto",
    height: "100%",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  imageContainerMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
    },
  },
}));
export default function Seasonal() {
  const classes = useStyles();
  const text = require("./en.json");

  return (
    <div className={classes.container}>
      <Grid container direction="row" justify="center">
        <Grid item className={classes.contentContainer}>
          <Grid justify="center" container direction="column">
            <Grid item className={classes.titleContainer}>
              <p className={classes.title}>{text.title}</p>
            </Grid>
            <Grid item className={classes.imageContainerMobile}>
              <img src={text.image_src} className={classes.seasonImage} />
            </Grid>
            <Grid item>
              <p className={classes.description}>{text.description}</p>
            </Grid>
            <Grid item style={{ maxWidth: "200px", paddingBottom: "20px" }}>
              <BaseButton external={true} url={text.src} text={"Listen Now"} />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item className={classes.imageContainer}>
          <img src={text.image_src} className={classes.seasonImage} />
        </Grid>
      </Grid>
    </div>
  );
}
