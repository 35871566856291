import React from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  progress: {
    fontSize: "50px",
    color:"inherit",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },
  },
}));
export default function BlogsLoader() {
  const classes = useStyles();

  return (
    <div style={{ marginTop: "100px" }}>
      <CircularProgress className={classes.progress} />
    </div>
  );
}
