import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import About from "./components/About";
import Blogs from "./components/Blogs";
import InstagramFooter from "./components/SocialMedia/InstagramFooter";
import ScrollToTop from "./helpers/ScrollToTop";
import Services from "./components/Services";

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={About} />
            <Route exact path="/blogs" component={Blogs} />
            <Route exact path="/ministries" component={Services}/>
          </Switch>
        </ScrollToTop>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
