import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../images/about.jpg";
import { Parallax } from "react-parallax";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "20px",
    marginBottom: "40px",
    height: "100%",
  },
  image: {
    width: "100%",
  },
  imageContainer: {
    maxHeight: "60vh",
    overflow: "hidden",
  },
  descriptionContainer: {
    //backgroundImage:"url("+"https://i.pinimg.com/originals/38/ed/c7/38edc73d7ddb9f00dd6a6dab3790497e.jpg"+")",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    textAlign: "left",
    marginLeft: "auto",
    paddingTop: "20px",
    paddingBottom: "20px",
    marginRight: "auto",
    fontSize: "22px",
    width: "100%",
    "@media(max-width:800px)": {
      fontSize: "16px",
      width: "100%",
      padding: "0px",
      paddingTop: "14px",
      paddingBottom: "14px",
    },
  },
  descriptionText: {
    opacity: "1",
    maxWidth: "550px",
    marginRight: "auto",
    marginLeft: "auto",
    "@media(max-width:800px)": {
      width: "80%",
      paddingTop: "16px",
    },
  },
  parallax: {
    backgroundImage: `url(${image})`,
    /* Full height */
    height: "225px",
    /* Create the parallax scrolling effect */
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
  },
}));

const text = [
  "Praise and worship is about making a difference in peoples lives using our talents. Starting with people that contribute to the ministry until those who listen to it. True praise and worship is heard in people’s hearts.",
];

export default function Description() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Parallax bgImage={image} strength={-100}>
        <div style={{ height: 300 }}></div>
      </Parallax>
      <div className={classes.descriptionContainer}>
        {text.map((item, index) => (
          <div key={index} className={classes.descriptionText}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}
