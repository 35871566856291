import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ScrollToTop from "../../../../helpers/ScrollToTop";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    height: "40vh",
    overflow: "hidden",
    margin: "auto",
    width: "60%",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "90%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      width: "50%",
    },
    maxWidth:"700px"
  },
  image: {
    width: "100%",
    height: "auto",
  },
  imageCenter: {
    width: "100%",
    height: "auto",
    transform: "translateY(-30%)",
  },
  title: {
    fontSize: "40px",
    maxWidth: "40%",
    paddingTop: "100px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "26px",
      paddingTop: "20px",
      maxWidth: "70%",
      marginBottom: "20px",
    },
  },
  content: {
    width: "40%",
    fontSize: "22px",
    margin: "auto",
    textAlign: "left",
    [theme.breakpoints.up("xl")]: {
      width: "35%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: "75%",
    },
  },
}));
export default function Blog(props) {
  const { title, paragraphs, id, src, centered, config, author } = props;
  const classes = useStyles();
  return (
    <ScrollToTop>
      <Grid container direction="column" justify="center">
        <Grid item style={{ maxHeight: "300px" }}>
          <h2 >{title}</h2>
        </Grid>
        <Grid item style={{ marginBottom: "28px" }}>
          <div className={classes.imageContainer}>
            <img
              src={src}
              className={centered ? classes.imageCenter : classes.image}
            />
          </div>
          <div>
            <p>Author : {author}</p>
          </div>
        </Grid>
        <Grid item className={classes.content}>
          {paragraphs.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </Grid>
      </Grid>
    </ScrollToTop>
  );
}
