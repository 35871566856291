import React from "react";
import ReactPlayer from "react-player/lazy";

export default function YoutubeVideo(props) {
  const { src } = props;
  return (
    <div>
      <ReactPlayer width={"100%"} url={src} controls={true} />
    </div>
  );
}
