import React from "react";
import Carousel from "react-multi-carousel";
import YoutubeDisplay from "../../YoutubeDisplay/YoutubeDisplay";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@material-ui/core/styles";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 3,
    paritialVisibilityGutter: 30,
  },
  md: {
    breakpoint: { max: 1500, min: 1000 },
    items: 2,
    paritialVisibilityGutter: 70,
  },
  tablet: {
    breakpoint: { max: 1000, min: 700 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  sm:{
    breakpoint: { max: 700, min: 600 },
    items: 1,
    paritialVisibilityGutter: 100,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    paritialVisibilityGutter: 25,
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
      margin:"auto",
      width:"90%",
      [theme.breakpoints.down('md')]:{
          width:"90%"
      }
  },
}));
export default function CarouselVideo(props) {
  const { videos } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Carousel partialVisbile responsive={responsive}>
        {videos.map((item, index) => {
          return (
            <div style={{margin:"auto"}} key={index}>
              <YoutubeDisplay src={item.src} description={item.description} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
