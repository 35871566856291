import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../../../images/intro.jpg";
import { Button } from "@material-ui/core";
//import MusicPlatformSelectionModal from "../MusicPlatformSelectionModal";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "100px",
  },
  bigImage: {
    height: "40vh",
    width: "100vw",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
    backgroundImage: `url(${image})`,
    "@media(max-width:700px)": {
      height: "40vh",
    },
  },
  title:{
      fontSize:"30px",
      fontWeight:"600",
  },
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.1)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    ":hover&": {
      background: "rgba(0, 0, 0, 0.4)",
    },
  },
  titleContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    color: "white",
    "@media(max-width:700px)": {
      marginLeft: "0%",
    },
  },
  buttonListenNow: {
    borderColor: "white",
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    borderWidth: "4px",
    borderRadius: "0px",
    "@media(max-width:700px)": {
      fontSize: "12px",
      borderWidth: "3px",
    },
  },
}));

export default function ImageIntro() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);

  const resetDialogState = () => {
    setOpenDialog(false);
  };

  return (
    <div className={classes.bigImage}>
      <div className={classes.overlay}>
        {/* <p>A full width background image built with HTML CSS.</p> */}
        <div className={classes.titleContainer}>
          <div className={classes.title}>Youth</div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo
          </p>
        </div>
      </div>
    </div>
  );
}
