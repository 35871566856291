import React from "react";
import { Parallax, Background } from "react-parallax";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: "22px",
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
  },
  insideStyles: {
    background: "transparent",
    padding: 15,
    color: "white",
    position: "absolute",
    width: "40%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "50%",
    },
  },
  container: {
    margin:"auto",
    marginBottom: "50px",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "10px",
      marginBottom: "50px",
    },
  },
  image:{
    transform: "translateY(-40%)",
    [theme.breakpoints.down('sm')]:{
      width:"100%",
    }
  }
}));
const insideStyles = {
  backgrounColor: "transparent",
  padding: 15,
  position: "absolute",
  width: "200px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

export default function AboutSecondaryContent() {
  const classes = useStyles();
  const image = "https://ds-web-media.s3.amazonaws.com/with_children_1.jpg";
  return (
    <div className={classes.container}>
      <Parallax bgClassName={classes.image}  bgImage={image} strength={-500}>
        <div style={{ height: 500 }}>
          <div style={insideStyles}>HTML inside the parallax</div>
        </div>
      </Parallax>
    </div>
  );
}
