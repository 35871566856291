import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#BDBAB8",
    paddingTop: "30px",
    paddingBottom: "30px",
    marginBottom: "35px",
  },
  title: {
    color: "white",
    fontSize: "36px",
    fontWeight: "bold",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "26px",
    },
  },
  inquiriesContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  inquiry: {
    padding: "8px",
  },
  inquiries: {
    color: "white",
    fontSize: "20px",
    width: "40%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      fontSize: "16px",
    },
  },
  questions: {
    fontWeight: "bolder",
  },
  email: {
    color: "white",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "300",
    letterSpacing: "0.5px",
    transitionProperty: "font-size",
    transitionDuration: "2s",
    transitionDelay: "0.0s",
    ":hover&": {
      fontSize: "22px",
    },
  },
}));

const inquiries = [
  {
    question: "Intrested in a Colab or Joining the service",
  },
  {
    question: "Need help with your service or equipement",
  },
  {
    question: "Want to put a request for a song?",
  },
];
export default function ContactIntro() {
  const classes = useStyles();

  const Inquiry = (question, answer) => (
    <Grid item className={classes.inquiry}>
      <div className={classes.questions}>{question}</div>
      <div>{answer}</div>
    </Grid>
  );
  return (
    <div className={classes.container}>
      <Grid container direction="column">
        <Grid item>
          <p className={classes.title}>How Can I Help?</p>
        </Grid>
        <Grid item className={classes.inquiriesContainer}>
          <Grid container direction="column" className={classes.inquiries}>
            {/* {inquiries.map((item, index) =>
              Inquiry(item.question, item.answer)
            )} */}
            <p className={classes.inquiry}>
              Ever wonder what makes a great worship team ? The ongoing
              contributions by volunteers whether it’s through an idea, video
              filming, video cast, story director, or vocals is what makes a
              great worship team.{" "}
            </p>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.email}
          >
            <Grid item>
              <IconButton>
                <MailOutlineIcon fontSize="inherit" />
              </IconButton>
            </Grid>
            <Grid item style={{ marginTop: "auto", marginBottom: "auto" }}>
              dahlbasht@gmail.com
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
