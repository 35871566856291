import { Card, CardContent, CardMedia, Divider, Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import YoutubeVideo from "../../YoutubeVideo";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 455,
    margin: "10px",
    maxHeight: "500px",
    height: "100%",
    marginBottom: "40px",
    [theme.breakpoints.between("xs", "sm")]: {
      width: 290,
      marginRight: "15px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 300,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: 340,
    },
  },
  media: {
    width: "100%",
  },
  title: {
    fontWeight: "bolder",
    textAlign: "left",
    textSizeAdjust: "100%",
    paddingBottom: "10px",
    color: "rgb(33, 42, 47)",
    letterSpacing: "0.5px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  cardContent: {
    padding: "20px",
    flexGrow: "1",
  },
  descriptionContainer: {
    paddingTop: "12px",
    flexGrow: "1",
    fontWeight: 400,
    color: "rgb(33, 42, 47)",
    margin: "0",
  },
  description: {
    margin: "0px",
    textAlign:"left",
  },
}));
export default function YoutubeCard(props) {
  const { title, src, type, description } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root} raised={true}>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        style={{ width: "100%", height: "100%" }}
      >
        <Grid item className={classes.media}>
          <YoutubeVideo src={src} />
        </Grid>
        <CardContent classes={{ root: classes.cardContent }}>
          <Grid item>
            <div className={classes.title}>
              <div>{title}</div>
            </div>
            <Divider />
            <div className={classes.descriptionContainer}>
              <p className={classes.description}>{description}</p>
            </div>
          </Grid>
        </CardContent>
      </Grid>
    </Card>
  );
}
