import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MobileDrawer from "./components/MobileDrawer";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MusicNoteOutlinedIcon from "@material-ui/icons/MusicNoteOutlined";
import MusicPlatformSelectionModal from "../Home/components/MusicPlatformSelectionModal";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  menuDesktopItem: {
    wordSpacing: "normal",
    textShadow: "none",
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: "14px",
    backgroundColor: "transparent",
    color: "rgb(33, 42, 47)",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appBarTransparent: {
    backgroundColor: "rgba(67, 129, 168,0.5)",
  },
  appBarSolid: {
    boxShadow: "none",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(33, 42, 47)",
  },
}));

export default function Navigation() {
  const classes = useStyles();

  const navlinks = require('./navlinks.json');
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const history = useHistory();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const resetDialogState = () => {
    setOpenDialog(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = isMobileMenuOpen && (
    <MobileDrawer reset={handleMenuClose} />
  );
  const MusicDialog = openDialog && (
    <MusicPlatformSelectionModal
      reset={resetDialogState}
      openDialog={openDialog}
    />
  );
  return (
    <div className={classes.grow}>
      {MusicDialog}
      <AppBar
        className={classes.appBarSolid}
        position="fixed"
      >
        <Toolbar>
          <IconButton onClick={() => setOpenDialog(true)}>
            <MusicNoteOutlinedIcon style={{ color: "black" }} />
          </IconButton>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {navlinks.navLinks.map((item, index) => (
              <div key={index}>
                <Button
                  onClick={() => history.push(item.url)}
                  className={classes.menuDesktopItem}
                >
                  {item.name}
                </Button>
              </div>
            ))}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
