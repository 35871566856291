import { Grid } from "@material-ui/core";
import React from "react";
import YoutubeVideo from "../YoutubeVideo";
import ImageIntro from "./components/ImageIntro";
import { makeStyles } from "@material-ui/core/styles";
import HomeIntro from "./components/HomeIntro";
import Seasonal from "./components/Seasonal";
import HomeIntroDescription from "./components/HomeIntroDescription";
import CarouselYoutube from "../Carousel/CarouselYoutube";
import HomeIntroV2 from "./components/HomeIntroV2.js";
import HomeButtonDisplay from "./components/HomeButtonDisplay/HomeButtonDisplay";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "visible",
    marginTop: "70px",
    height:"auto",
    marginBottom:"50px",
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "50px",
    },
    justifyContent: "space-around",
  },
  sticky: {
    position: "-webkit-sticky", /* for Safari */
    position: "sticky",
    zIndex:"1",
    width:"100%",
    paddingTop:"30px",
    backgroundColor:"white",
    top: 0,
    alignSelf: "flex-start",/* <-- this is the fix */
  },
  videoContainer:{
    width:"60%",
    height:"400px",
    margin:"auto",
    marginTop:"70px",
  },
  carousel:{
    paddingTop:"20px",
    width:"85%",
    margin:"auto",
    height:"600px",
    paddingBottom:"60px",
    [theme.breakpoints.between('xs', 'sm')]:{
      width:"100%",
    },
    [theme.breakpoints.between('md', 'lg')]:{
      width:"100%",
    }
  },
  title:{
    fontSize:"36px"
  }
}));

export default function Home() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Grid container direction="column">
        <Grid item>
          <ImageIntro />
        </Grid>
        <Grid item>
          <HomeIntroV2/>
        </Grid>
        <Grid item>
          <HomeButtonDisplay/>
        </Grid>
        <Grid item>
          <Seasonal/>
        </Grid>
        <Grid item className={classes.title}>
          <h2 className={classes.title}>Discography</h2>
        </Grid>
        <Grid item>
          <div className={classes.carousel}>
          <CarouselYoutube/>
          </div>
        </Grid>
        {/* <Grid item>
          <div className={classes.videoContainer}>
          <YoutubeVideo src="https://www.youtube.com/watch?v=i67lhAHnW-A" />
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
}
