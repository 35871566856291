import { Box, Grid } from "@material-ui/core";
import React from "react";
import InstagramFooter from "../SocialMedia/InstagramFooter";
import QuickLinks from "./components/QuickLinks";
import SocialMedia from "./components/SocialMedia";

export default function Footer() {
  return (
    <div style={{overflow:"hidden"}} >
      <InstagramFooter/>
    <Box
      style={{
        backgroundColor: "#212a2f",
        marginTop: "20px",
        paddingTop: "30px",
        paddingBottom:"20px"
      }}
    >
      <Grid container direction="column" justify="center">
        <Grid item>
          <SocialMedia />
        </Grid>
        <Grid item>
          <QuickLinks/>
        </Grid>
      </Grid>
    </Box>
    </div>
  );
}
