import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { CloseOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  list: {
    width: 275,
  },
  logo: {
    width: "200px",
  },
  fullList: {
    width: "auto",
  },
  listItem: {
    wordSpacing: "normal",
    textShadow: "none",
    textTransform: "uppercase",
    fontWeight: "bolder",
    fontSize: "14px",
    color: "rgb(33, 42, 47)",
  },
});

export default function MobileDrawer(props) {
  const classes = useStyles();
  
  const navlinks = require('../navlinks.json');
  const content = require('../../en.json');

  const [state, setState] = React.useState(true);
  const history = useHistory();

  const toggleDrawer = (open) => (event) => {
    props.reset();
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton
        onClick={() => history.push("/")}
        style={{ borderRadius: "0%" }}
      ></IconButton>
      <List>
        {navlinks.navLinks.map((item, index) => (
          <ListItem
            button
            key={item.name}
            onClick={() => history.push(item.url)}
          >
            <ListItemText
              classes={{ primary: classes.listItem }}
              primary={item.name}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[
          {
            name: "Facebook",
            url:
              "https://www.facebook.com/St-Joseph-Coptic-Orthodox-church-Ottawa-New-church-building-project-2021-105978408062404/?view_public_for=105978408062404Youtube",
          },
          {
            name: "YouTube",
            url:
              "https://www.youtube.com/channel/UChA-FNHsg9VJgVlbfjUzpkw/featured",
          },
        ].map((item, index) => (
          <ListItem
            button
            key={item.name}
            onClick={() => window.open(item.url)}
          >
            <ListItemIcon>
              {index === 0 ? (
                <FacebookIcon fontSize={"large"} />
              ) : (
                <YouTubeIcon fontSize={"large"} />
              )}
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {/* <div style={{textAlign:"center", marginTop:"10px"}}>
          {content.composedTitle.map((item) => 
          <div style={{padding:"8px"}}>
            {item}
            </div>
          )}
        </div> */}
        {list()}
      </SwipeableDrawer>
    </div>
  );
}
