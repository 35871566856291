import { Grid, IconButton, SvgIcon, Tab } from "@material-ui/core";
import React from "react";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import { makeStyles } from "@material-ui/core/styles";
import SpotifyImage from "../../images/Spotify_Icon_RGB_White.png";
import InstagramIcon from "@material-ui/icons/Instagram";
import AppleMusicIcon from "../../images/Apple_Music_Icon_wht_lg_072420.svg"

const styles = makeStyles((theme) => ({
  icons: {
    color: "white",
    fontSize: "40px",
    ['@media(max-width:800px)']:{
      fontSize:"30px"
    }
  },
  iconsImage:{
    width: "40px",
    ['@media(max-width:800px)']:{
      width:"30px"
    }
  }
}));
export default function SocialMedia() {
  const classes = styles();
  return (
    <Grid container direction="row" justify="center" style={{ color: "white" }}>
      <Grid item>
        <IconButton
          onClick={() =>
            window.open(
              "https://open.spotify.com/artist/6Zbdtl0M81DYdfxim2PxU7"
            )
          }
        >
          <img className={classes.iconsImage} src={SpotifyImage} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={() =>
            window.open(
              "https://www.youtube.com/dahliabashta"
            )
          } className={classes.icons}>
          <YouTubeIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Grid item>
      <IconButton onClick={() =>
            window.open(
              "https://www.instagram.com/dahlbasht/"
            )
          } className={classes.icons}>
          <InstagramIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => window.open("https://www.facebook.com/dahlbash/")}
          className={classes.icons}
        >
          <FacebookIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={()=> window.open("https://music.apple.com/us/artist/dahlia-bashta/1481779817")}>
            <img className={classes.iconsImage} src={AppleMusicIcon}/>
        </IconButton>
      </Grid>
    </Grid>
  );
}
