import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Verse from "../../../../Verse";

const useStyles = makeStyles((theme) => ({
  title: {
    color:"white",
    fontSize: "46px",
    [theme.breakpoints.down('sm')]:{
      fontSize:"32px",
    }
  },
  container:{
    margin:"auto",
    padding:"10px",
  }
}));

export default function ChildrenIntro() {
  const classes = useStyles();

  return (
    <div className={classes.container} >
      <Grid container direction="row" justify="center">
        <Grid item>
          <Grid container direction="column" justify="center">
            <Grid item>
              <div className={classes.title}>Children</div>
            </Grid>
            <Grid item>
              <Verse
                reference={"Matthew 19:14"}
                text={
                  "Jesus said, ‘Let the little children come to me, and do not hinder them, for the kingdom of heaven belongs to such as these.’"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </div>
  );
}
