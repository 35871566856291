import { Grid } from "@material-ui/core";
import React from "react";
import Title from "./components/Title";
import { makeStyles } from "@material-ui/core/styles";
import Description from "./components/Description";
import AboutIntroParallax from "./components/AboutIntroParallax";
import AboutSecondaryContent from "./components/AboutSecondaryContent";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
  },
  desktopParallax: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  containerHeader: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileParallax: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function About() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container direction="column">
        <Grid item className={classes.containerHeader}>
          <Grid container direction="column">
            <Grid item>
              <Title text={["Endless", "Treasures", "Music"]} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.desktopParallax}>
          <AboutIntroParallax />
        </Grid>
        <Grid
          item
          className={classes.mobileParallax}
          style={{ backgroundColor: "transparent" }}
        >
          <Description />
        </Grid>
        <Grid item>
          <AboutSecondaryContent />
        </Grid>
      </Grid>
    </div>
  );
}
