import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import image from "../../images/about_2.jpg";
import { useTrail, animated } from "react-spring";
import { useWindowSize } from "react-use";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: "22px",
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
  },
  content_secondary: {
    margin: "auto",
    width: "50%",
  },
}));

export default function AboutIntroParallax() {
  const items = ["Endless", "Treasure", "Music"];
  const config = { mass: 2, tension: 2000, friction: 400 };
  const [scrollWidth, setScrollWidth] = React.useState(0);
  const classes = useStyles();
  const [toggle, set] = React.useState(true);
  const sideRef = React.useRef(null);
  const size = useWindowSize();
  const image3 = "https://ds-web-media.s3.amazonaws.com/inside_church.jpg"
  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 40 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });
  React.useLayoutEffect(() => {
    if (sideRef.current) {
      let boxWidth = 0;

      [...sideRef.current.children].forEach((c) => {
        const childBox = c.getBoundingClientRect();
        boxWidth = boxWidth + childBox.width;
      });

      const w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      const totalScrollWidth = boxWidth > w ? boxWidth - w : 0;

      setScrollWidth(totalScrollWidth);
    }
  }, [size.height, size.width]);
  return (
    <ParallaxProvider>
      <div>
        <div className="content-block">
          <Parallax tagOuter="div" y={[10, 40]} className="intro-text">
            <h1 style={{ minHeight: items.length * 40 }}>
              {trail.map(({ x, height, ...rest }, index) => (
                <animated.span
                  key={items[index]}
                  className="trails-text"
                  style={{
                    ...rest,
                    transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
                  }}
                >
                  <animated.span style={{ height }}>
                    {items[index]}
                  </animated.span>
                </animated.span>
              ))}
            </h1>
            <p className={classes.description}>
              Praise and worship is about making a difference in peoples lives
              using our talents. Starting with people that contribute to the
              ministry until those who listen to it. True praise and worship is
              heard in people’s hearts.
            </p>
          </Parallax>
          <Parallax y={[10, 80]} tagInner="figure" className="intro-image">
            <img
              src={image}
              alt="toet"
              //   src="https://images.unsplash.com/photo-1568093858174-0f391ea21c45?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
            />
          </Parallax>
        </div>
      </div>
    </ParallaxProvider>
  );
}
