import React from "react";
import { Parallax, Background } from "react-parallax";
import { makeStyles } from "@material-ui/core";
import ChildrenIntro from "./ChildrenIntro";
import image from "../../../../images/children_services.jpg";

const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: "22px",
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
  },
  insideStyles: {
    background: "transparent",
    color: "white",
    padding: 15,
    position: "absolute",
    width: "30%",
    top: "50%",
    left: "70%",
    transform: "translate(-50%,-50%)",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      left: "60%",
    },
  },
  container: {
    margin: "auto",
  },
  image: {
    transform: "translateY(-40%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mainContainer: {
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
}));
const insideStyles = {
  backgrounColor: "transparent",
  padding: 15,
  position: "absolute",
  width: "200px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

export default function ChildrenIntroV2() {
  const classes = useStyles();
  const image = "https://ds-web-media.s3.amazonaws.com/inside_church.jpg"
  return (
    <div className={classes.container}>
      <Parallax bgClassName={classes.image} bgImage={image} strength={-500}>
        <div className={classes.mainContainer}>
          <div className={classes.insideStyles}>
            <ChildrenIntro />
          </div>
        </div>
      </Parallax>
    </div>
  );
}
