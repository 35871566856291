import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  verse: {
    fontFamily:"Charter,Georgia,Times,Serif",
    fontSize: "24px",
    color:"white",
    maxWidth:600,
    padding: "12px",
    "@media(max-width: 700px)": {
      fontSize: "16px",
    },
    textAlign:"left",
  },
  text: {
    fontSize:"20px",
    marginBottom: "0px",
  },
  reference: {
    padding:"10px",
    fontSize:"22px",
    marginTop: "0px",
    fontWeight: "500",
    fontFamily:"Memphis W01,Times,Serif",
    [theme.breakpoints.down('sm')]:{
      fontFamily:"18px",
    },
    paddingLeft:0,
  },
}));

export default function Verse(props) {
  const { text, reference } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.verse}>
      <Grid item>
        <p className={classes.text}>"{text}"</p>
      </Grid>
      <Grid item>
        <p className={classes.reference}>{reference}</p>
      </Grid>
    </Grid>
  );
}
