import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CarouselVideo from "./CarouselVideo";

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    width: "50%",
    height: "40vw",
    overflow: "hidden",
    [theme.breakpoints.between("sm","md")]: {
      width: "60%",
      height: "60vw",
    },
    [theme.breakpoints.between('xs',"sm")]: {
      width: "100%",
      height: "50vw",
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      height: "90vw",
    },
  },
  showCaseContainer: {
    width: "40%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  titleContainer: {},
  root: {
    width: "100%",
  },
  img: {
    width: "100%",
    transform: "translateY(-10%)",
  },
  bigImage: {
    height: "100%",
    width: "100%",
    position: "relative",
    backgroundSize: "cover",
    backgroundPositionY: "10%",
    backgroundImage: `url(https://ds-web-media.s3.amazonaws.com/with_youth_2.jpg)`,
    "@media(max-width:700px)": {
      height: "40vh",
    },
    [theme.breakpoints.between('sm', 'md')]:{
      backgroundPositionY: "20%",
    }
  },
  title: {
    fontSize: "42px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  overlay: {
    position: "absolute",
    height: "50%",
    width: "100%",
    margin: "auto",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.1)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    ":hover&": {
      background: "rgba(0, 0, 0, 0.4)",
    },
  },
  description: {
    width: "50%",
    margin: "auto",
    [theme.breakpoints.down("sm")]:{
      width:"80%",
    }
  },
}));
export default function YouthIntro() {
  const classes = useStyles();
  const videos = [
    {
      title: "You will free Me",
      src: "https://www.youtube.com/watch?v=VOLG0LJ0aPo",
      description:
        "“And because you belong to him, the power of the life-giving Spirit has freed you from the power of sin that leads to death.”",
    },
    {
      title: "The God Who Stays",
      src: "https://www.youtube.com/watch?v=iCobz-2UXJY",
      description:
        "The God Who Stays Cover Song - Original Song by Matthew West",
    },
  ];
  return (
    <div>
      <Grid className={classes.root} justify="center" container direction="row">
        <Grid item className={classes.imgContainer}>
          <div className={classes.bigImage}>
            <div className={classes.overlay}>
              {/* <p>A full width background image built with HTML CSS.</p> */}
              <div className={classes.titleContainer}>
                <div className={classes.title}>YOUTH</div>
                <p className={classes.description}>
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item className={classes.showCaseContainer}>
          <div style={{ width: "100%", height: "100%" }}>
            <CarouselVideo videos={videos} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
