import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "8px",
    color: "#343534",
    borderColor: "#343534",
    fontSize: "14px",
    fontWeight:"700",
    borderWidth: "3px",
    borderRadius: "0px",
    width: "100%",
    ":hover&": {
      color: "white",
      backgroundColor: "#343534",
    },
  },
}));
export default function BaseButton(props) {
  const { text, url, external } = props;
  const history = useHistory();
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      classes={{ root: classes.button }}
      onClick={() => (external ? window.open(url) : history.push(url))}
    >
      {text}
    </Button>
  );
}
