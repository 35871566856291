import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding:"12px",
    display: "block",
    lineHeight: "24px",
    textSizeAdjust: "100%",
  },
  insta: {
    color:"black",
    cursor: "pointer",
    fontFamily:"Sohne,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;",
    fontSize:"42px",
    fontWeight:"200",
    letterSpacing:"0.64px",
    ['@media(max-width:700px)']:{
      fontSize:"30px",
    }
  },
}));
export default function InstagramFooter() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h2>
        <span>
          <a href="https://www.instagram.com/dahlbasht/" className={classes.insta} >Instagram @dbashta</a>
        </span>
      </h2>
    </div>
  );
}
