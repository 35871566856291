import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import YoutubeDisplay from "../YoutubeDisplay/YoutubeDisplay";
import CarouselVideo from "./components/CarouselVideo";
import ChildrenIntro from "./components/ChildrenIntro";
import ChildrenIntroV2 from "./components/ChildrenIntroV2";

export default class Children extends Component {
  render() {
    const videos = [
      {
        src: "https://www.youtube.com/watch?v=SSZaRAUcWLw&t=4s",
        description:
          "Je Penniot ( Our Father) - The Lord's Prayer: Our Father which art in heaven, Hallowed be thy name",
      },
      {
        src: "https://www.youtube.com/watch?v=K6mkLI3rnA8",
        description:
          "Lord of All Creation - Children's Worship You are Lord of All creation ",
      },
      {
        src: "https://www.youtube.com/watch?v=NEwNFqVcDKo",
        description:
          "Early every Sunday as we gather in the Church. The children use their eyes, ears, feet and hands to enjoy different parts of the Church prayer.",
      },
    ];
    return (
      <div style={{ marginTop: "65px", width: "100%" }}>
        <Grid container direction="column" style={{ width: "100%" }}>
          <Grid item>
            <ChildrenIntroV2 />
          </Grid>
          <Grid
            item
            style={{
              paddingTop: "40px",
              paddingBottom:"60px",
              width: "100%",
              backgroundColor: "#F9F9F7",
            }}
          >
            <CarouselVideo videos={videos} />
          </Grid>
        </Grid>
      </div>
    );
  }
}
