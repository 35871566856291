import { Grid, TextField, Button } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import helper from "../../../../helpers";
import api from "../../../../api";

const styles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    width: "40%",
    padding: "12px",
    marginLeft: "auto",
    marginTop: "10px",
    marginBottom: "30px",
    marginRight: "auto",
    [theme.breakpoints.between("sm", "md")]: {
      width: "60%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: "80%",
    },
  },
  button: {
    padding: "12px",
    color: "#343534",
    borderColor: "#343534",
    fontSize: "14px",
    borderWidth: "3px",
    borderRadius: "0px",
    width: "50%",
    ":hover&": {
      color: "white",
      backgroundColor: "#343534",
    },
  },
  girdItems: {
    padding: "12px",
  },
}));

export default function ContactForm() {
  const classes = styles();

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  const [fullName, setFullName] = React.useState("");
  const [fullNameError, setFullNameError] = React.useState(false);
  const [fullNameErrorMessage, setFullNameErrorMessage] = React.useState("");

  const [message, setMessage] = React.useState("");
  const [messageError, setMessageError] = React.useState(false);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [subjectError, setSubjectError] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const validateForm = () => {
    let valid = true;
    if (fullName.length < 4) {
      setFullNameError(true);
      setFullNameErrorMessage("Please enter a valid Full Name.");
      valid = false;
    }
    if (message.length < 5) {
      setMessageError(true);
      valid = false;
    }
    return valid;
  };

  const loader = loading && (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  const submit = (e) => {
    if(!validateForm()){
      return;
    }
    e.preventDefault();
    setLoading(true);
    api
      .sendInquiryEmail({
        firstName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        message: message,
      })
      .then((response) => {
        if(response.status === 200){
          setLoading(false);
          alert('Your email was sent.')
        }
      }).catch((e) => {
        alert("Something went wrong")
        setLoading(false)
      });
  };

  return (
    <div className={classes.container}>
      {loader}
      <form onSubmit={submit}>
        <Grid container direction="column" justify="center">
          <Grid item className={classes.girdItems}>
            <TextField
              required={true}
              value={fullName}
              error={fullNameError}
              helperText={fullNameErrorMessage}
              fullWidth
              label="Full Name"
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>
          <Grid item className={classes.girdItems}>
            <TextField
              required={true}
              error={emailError}
              type={"email"}
              value={email}
              fullWidth
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item className={classes.girdItems}>
            <TextField
              required={true}
              error={subjectError}
              value={phoneNumber}
              fullWidth
              type={"tel"}
              label="Phone Number"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Grid>
          <Grid item className={classes.girdItems}>
            <TextField
              required={true}
              error={messageError}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              fullWidth
              label="Message"
              multiline
              rows={7}
            />
          </Grid>
          <Grid item style={{ padding: "12px", marginTop: "10px" }}>
            <Button
              type={"submit"}
              endIcon={<SendIcon />}
              variant="outlined"
              classes={{ root: classes.button }}
            >
              SEND
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
